html, body {
    width: 100%;
    background-color: #fff;
    margin: 0;
    font-family: "Helvetica Neue", sans-serif;
    color: #0A0D10;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: none;
}

a, .image-gallery-thumbnails, button, iframe {
    z-index: 10;
    position: relative;
    cursor: none;
    pointer-events: all;
}
