.Repo {
    display: flex;
    justify-content: space-between;
    border: 1px #E1E5F4 solid;
    height: calc(100 / 16 * 1rem);
    margin-bottom: calc(12 / 16 * 1rem);
    align-items: center;
    padding: calc(20 / 16 * 1rem);
    box-sizing: border-box;
}
.RepoLink {
    text-decoration: none;
}

.repoName {
    color: #205491;
    font-weight: bold;
    font-size: calc(14 / 16 * 1rem);
}

.repoDescription {
    font-size: calc(12 / 16 * 1rem);
    color: #979797;
}
