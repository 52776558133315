.aboutMe {
    width: 70vw;
    display: flex;
    position: relative;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profile {
    display: flex;
    width: 100%;
}

.aboutMe p {
    line-height: 1.4;
}

.aboutMe p img {
    height: 3vw;
}

.description h1 {
    font-weight: 400;
    font-size: 3.5vw;
}

.descriptionText {
    font-weight: 300;
    font-size: 1.5vw;
}

.descriptionText p {
    margin-bottom: 5px;
}

.descriptionText span {
    font-weight: 400;
}

.descriptionText a {
    color: #1574C3;
    text-decoration: none;
}

.finalDescription {
    font-weight: 400;
    font-size: 2vw;
    margin-top: 2vw;
}

@media only screen and (max-width: 600px) {
    .profile {
        flex-direction: column;
    }

    .description h1 {
        font-size: 5vw;
    }

    .descriptionText {
        font-size: 3.5vw;
    }

    .finalDescription {
        font-size: 3.5vw;
    }
}
