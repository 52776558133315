.footerContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer {
    height: 20vmin;
    width: 70vw;
    display: flex;
    font-size: 3vw;
    font-weight: 400;
    padding-bottom: 5vw;
}

.footerLine {
    width: 100%;
    display: block;
}

.footer a {
    color: #1A3E87;
    text-decoration: none;
}

.mailMe {
    flex: 1;
    width: 80%;
}

.dogContainer {
    width: 20%;
}

.dog {
    width: 100%;
    height: auto;
}

.footer a:hover {
    text-decoration: underline;
}
