.projectWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    flex-direction: column;
    padding-bottom: 3vw;
    box-sizing: border-box;
}

.project {
    display: flex;
    font-weight: 300;
    font-size: 1.5vw;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    width: 70vw;
}

.image {
    width: 35vw;
    min-height: 200px;
    margin-right: 5vw;
}

.image img {
    width: 100%;
}

.descriptionWrapper {
    flex: 1;
    color: #333333;
}

.descriptionWrapper h2 {
    color: #0A0D10;
    font-size: 3vw;
    margin: -0.8vw 0 0;
}

.descriptionWrapper a {
    text-decoration: none;
    display: block;
    color: #1A3E87;
    font-size: 1.5vw;
}

.description {
    margin-top: 5vmin;
}
.description a {
    display: inline;
}

.images {
    display: flex;
    height: 25vw;
    width: 70vw;
    justify-content: space-between;
    padding-top: 2vw;
}

.images img {
    padding-right: 2vw;
    width: 20vw;
    height: auto;
    flex: 0 0 auto;
    object-fit: contain;
}

.images img:last-child{
    padding-right: 0;
}

@media only screen and (max-width: 600px) {
    .projectWrapper {
        height: 60vh;
    }
    .project {
        flex-direction: column;
    }

    .image {
        width: 100%;
        min-height: auto;
        margin: 5vw 0 0;
        order: 2;
    }

    .descriptionWrapper h2 {
        font-size: 5vw;
    }

    .descriptionWrapper a {
        font-size: 3vw;
    }

    .project {
        font-size: 3.5vw;
    }
}
