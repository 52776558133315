.logo {
    width: 100%;
    display: flex;
    justify-content: center;
}

.logo a {
    text-decoration: none;
    color: #0A0D10;
}

.logo img {
    height: 100%;
}

.V, .C {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 4vmin;
}

.C {
    margin-left: 9vmin;
}

.label {
    margin-top: 4vmin;
    font-weight: 500;
    position: relative;
    font-size: 16px;
    pointer-events: all;
    z-index: 2;
}


.line {
    position: absolute;
    line-height: 1;
    top: 60%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 3px;
    width: 30vmin;
    background: url("../../elements/line.jpg") no-repeat center;
    opacity: 1;
    transition: opacity 150ms ease-in-out;
}

.C .line {
    transform: translateX(-50%) translateY(-50%) rotate(-180deg);
}

a:hover .line {
    opacity: 0;
}
