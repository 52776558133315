section, div, header {
    pointer-events: none;
}

p {
    margin-block-start: 0;
    margin-block-end: 0;
}

canvas {
    pointer-events: all;
}

a {
    pointer-events: all;
}

a > * {
    pointer-events: all;
}

.mainHeader {
    position: relative;
    padding: 0 4vw 0 4vw;
    height: 15vh;
    min-height: 80px;
    display: flex;
    align-items: center;
}

.roleWrapper {
    position: absolute;
    bottom: 0;
}

.aboutMeWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 15vh);
}

.opensourceWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 6vw;
}

.opensource {
    width: 70vw;
    pointer-events: all;
    position: relative;
    z-index: 2;
}

.opensource h3 {
    display: flex;
    align-items: center;
}

.projectsWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 4vw;
    position: relative;
}

.element1 {
    position: absolute;
    transform: translateY(-50%);
    right: 20%;
    bottom: -10vw;
    width: 5vw;
    z-index: 0;
}

.element2 {
    position: absolute;
    width: 3vw;
    left: 5vw;
    top: 13%;
}

.element3 {
    position: absolute;
    width: 5vw;
    right: 7vw;
    top: 32%;
}

.element4 {
    position: absolute;
    width: 3vw;
    left: 50%;
    transform: translateX(-50%);
    top: 36%;
}

.element5 {
    position: absolute;
    width: 7vw;
    right: 7vw;
    top: 55%;
}

.element6 {
    position: absolute;
    width: 3vw;
    left: 10vw;
    top: 63%;
}

.element7 {
    position: absolute;
    width: 3vw;
    left: 7vw;
    top: 75%;
}

.element8 {
    position: absolute;
    width: 4vw;
    right: 13vw;
    top: 82%;
}

@media only screen and (max-width: 600px) {
    section, div, header, html, body, a {
        pointer-events: all;
        cursor: auto;
    }

    .element1 {
        display: none;
    }
}

