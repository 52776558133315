.cursorWrapper {
    position: fixed;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;
    will-change: transform;
    /*transition: transform 0ms;*/
    transform-origin: center center;
    pointer-events: none;
    z-index: 11;
}

.cursor {
    width: 100%;
    height: 100%;
    background: url("./cursor2.png") no-repeat;
    background-size: cover;
    animation: rotateKeyframe 5000ms linear infinite;
}

@keyframes rotateKeyframe {
    0% {
        transform: scale(0.8) rotate(0deg);
    }
    50% {
        transform: scale(1.2) rotate(180deg);
    }
    100% {
        transform: scale(0.8) rotate(360deg);
    }
}
